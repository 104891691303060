import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { TrackModel } from '../../../../models/track.model';

@Component({
    selector: 'app-tracks-list',
    templateUrl: './tracks-list.component.html',
    styleUrls: ['./tracks-list.component.scss'],
})
export class TracksListComponent implements OnInit {
    public tracks: TrackModel[];
    public tracksLogo = [
        '/../../assets/images/icons/Academic Weapons.png',
        '/../../assets/images/icons/Digital Administration.png',
        '/../../assets/images/icons/Medical Renaissance.png',
        '/../../assets/images/icons/Sustainable Development.png',
        '/../../assets/images/icons/Unity in Community.png',
    ];


    constructor(private apiService: ApiService) {
    }

    async ngOnInit() {
        this.tracks = await this.apiService.getTracks();
    }

    public hasJudgingCriteria(track: TrackModel): boolean {
        return !!(track.judgingCriteria && track.judgingCriteria.length);
    }

    public hasPrizes(track: TrackModel): boolean {
        return !!(track.prizes && track.prizes.length);
    }


    public hasExamples(track: TrackModel): boolean {
        return !!(track.examples && track.examples.length);
    }

    public hasDescription(track: TrackModel): boolean {
        return !!(track.shortDescription && track.shortDescription.length);
    }
}
