<div>
    <!-- Conditionally show full or partial content -->
    <p>{{ isExpanded ? fullText : truncatedText }}...</p>

    <!-- Button to toggle between read more/less -->
    <a class="read-more" (click)="toggleExpand()"
       [style.color] ="isPurple ? '#47009C' : '#FFDF40'"
       [innerHtml]="isExpanded ? 'Read Less &#x25B2;' : 'Read More &#x25BC;'"
    >
    </a>
</div>
