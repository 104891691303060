import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'app-tracks',
    templateUrl: './tracks.component.html',
    styleUrls: ['./tracks.component.scss'],
})
export class TracksComponent implements OnInit {
text = 'Tracks, tracks, tracks! You keep hearing about\n' +
    '                    them, but maybe you don’t really know what they\n' +
    '                    are. Let us explain! Tracks are different themes that\n' +
    '                    your project can be categorized under.\n' +
    '                    They will be\n' +
    '                    divided among the sponsors, who will also provide\n' +
    '                    the prizes and support for their specific track.\n' +
    '                    Your project can be eligible to receive awards for\n' +
    '                    one or more tracks, depending on the technologies\n' +
    '                    they use.\n' +
    '                    Pretty exciting, right?';
    constructor(private breakpointObserver: BreakpointObserver) {}
    mobile = false;
    ngOnInit(): void {
        // Listen to screen size changes
        this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
            this.mobile = result.matches;
        });
    }

}
