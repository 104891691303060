import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-read-more-box',
    templateUrl: './read-more-box.component.html',
    styleUrls: ['./read-more-box.component.scss'],
})
export class ReadMoreBoxComponent {

    @Input() isPurple = true;
    @Input()
    public fullText = 'This is the full content that you want to display. It may be long and need to be truncated.';
    isExpanded = false;

    // Number of characters to show initially
    truncationLength = 150;

    // Getter to return truncated text
    get truncatedText(): string {
        return this.fullText.length > this.truncationLength
            ? this.fullText.slice(0, this.truncationLength)
            : this.fullText;
    }

    // Toggle the expansion state
    toggleExpand() {
        this.isExpanded = !this.isExpanded;
    }
}
