<div class="workshops-background component inner-shadow-top" id="workshops">
    <div class="container">
        <div class="flex-container">
            <div class=" description-text">
                <div class="titles">Conference</div>
                <div *ngIf="mobile; else desktopTemplate">
                <app-read-more-box [fullText]=text [isPurple]="true"></app-read-more-box>
            </div>

                <ng-template #desktopTemplate>
                    <div>
                        The Conference is an insightful event featuring talks and panels by industry leaders, tech
                        experts and
                        civic issues experts. It provides participants with the opportunity to explore the latest trends
                        in
                        technology and how technology can help our society. Attendees can gain valuable knowledge,
                        discover new
                        tools and practices, and be inspired by the journeys of accomplished professionals. The
                        conference
                        fosters an environment of learning, discussion, and motivation, making it a must-attend event
                        for anyone
                        looking to deepen their understanding of the tech world and its future.
                    </div>
                </ng-template>

            </div>
            <div class="responsive-width p-4">
                <img src="../../../../../assets/images/Conferinta.jpg" class="conference-image">
            </div>
        </div>
    </div>
</div>
