import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-conference',
  templateUrl: './conference.component.html',
  styleUrls: ['./conference.component.scss'],
})
export class ConferenceComponent implements OnInit {
  mobile = false;

  text= ' The Conference is an insightful event featuring talks and panels by industry leaders, tech\n' +
      '                        experts and\n' +
      '                        civic issues experts. It provides participants with the opportunity to explore the latest trends\n' +
      '                        in\n' +
      '                        technology and how technology can help our society. Attendees can gain valuable knowledge,\n' +
      '                        discover new\n' +
      '                        tools and practices, and be inspired by the journeys of accomplished professionals. The\n' +
      '                        conference\n' +
      '                        fosters an environment of learning, discussion, and motivation, making it a must-attend event\n' +
      '                        for anyone\n' +
      '                        looking to deepen their understanding of the tech world and its future.'
  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    // Listen to screen size changes
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.mobile = result.matches;
    });
  }

}
