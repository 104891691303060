import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-networking-night',
  templateUrl: './networking-night.component.html',
  styleUrls: ['./networking-night.component.scss'],
})
export class NetworkingNightComponent implements OnInit {
 text = ' Networking Night is an event created to put together participants with industry professionals, fellow hackers, and other people interested in technology.\n' +
     '                    Whether you\'re seeking advice, feedback, or simply expanding your professional network, the Networking Night is a key highlight of the Unihack experience.\n' +
     '                    Also if you want to join our hackathon you don\'t have a team there is nothing to worry about! The Networking Night is your opportunity to find a team or create one of your own!';
  constructor(private breakpointObserver: BreakpointObserver) {}
  mobile = false;
  ngOnInit(): void {
    // Listen to screen size changes
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.mobile = result.matches;
    });
  }
}
