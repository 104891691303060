<div class="workshops-background component inner-shadow-top" id="workshops">
    <div class="container">
        <div class="flex-container">
            <div class="description-text">
                <div class="titles">Workshops</div>
                <div *ngIf="isMobile; else desktopTemplate">
                    <app-read-more-box [fullText]=text [isPurple]="true"></app-read-more-box>
                </div>

                <ng-template #desktopTemplate>
                    The Workshops are hands-on, interactive sessions designed to help participants get access to
                    all the tools needed to work on their projects. Led by industry experts and experienced
                    mentors, these workshops cover a wide range of topics with a focus on both tech and civic
                    needs. Whether you're a beginner or an advanced learner, the workshops at Unihack offer
                    valuable insights and tools to help you succeed in your project.
                </ng-template>



            </div>
            <div class="responsive-width p-4">
                <img src="../../../../../assets/images/Workshop.jpg" class="workshop-image">
            </div>
        </div>

    </div>
</div>
