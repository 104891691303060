import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-workshops',
  templateUrl: './workshops.component.html',
  styleUrls: ['./workshops.component.scss'],
})
export class WorkshopsComponent implements OnInit {
  isMobile = false;
  text = 'The Workshops are hands-on, interactive sessions designed to help participants get access to\n' +
      '                all the tools needed to work on their projects. Led by industry experts and experienced\n' +
      '                mentors, these workshops cover a wide range of topics with a focus on both tech and civic\n' +
      '                needs. Whether you\'re a beginner or an advanced learner, the workshops at Unihack offer\n' +
      '                valuable insights and tools to help you succeed in your project.'
  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    // Listen to screen size changes
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }
}
