<div class="networking-night-background component inner-shadow-top" id="workshops">
    <div class="container">
        <div class="flex-container">
            <div class="description-text">
                <div class="titles">Networking Night</div>
                <div *ngIf="mobile; else desktopTemplate">
                    <app-read-more-box [fullText]=text [isPurple]="false"></app-read-more-box>
                </div>


                <div>
                    <ng-template #desktopTemplate>

                        Networking Night is an event created to put together participants with industry professionals,
                        fellow hackers, and other people interested in technology.
                        Whether you're seeking advice, feedback, or simply expanding your professional network, the
                        Networking Night is a key highlight of the Unihack experience.
                        Also if you want to join our hackathon you don't have a team there is nothing to worry about!
                        The Networking Night is your opportunity to find a team or create one of your own!
                    </ng-template>
                </div>


            </div>
            <div class="responsive-width p-4">
                <img src="../../../../../assets/images/Networking-Night.jpg" class="networking-night-image">
            </div>
        </div>
    </div>




