import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root',
})
export class PreregisterService {

  constructor(private http: HttpClient,
              private constants: ConstantsService) {
  }
  public AddPreregister(info: any): Promise<{ data: any }> {
    const url = `${this.constants.api}/Preregister/Add`;
    return this.http.post<{ data: any }>(url, info).toPromise();
  }
}
